import stl from "./Title.module.css"
import {firstName, lastName, profession} from "../../source/content";
import {useContext, useEffect, useRef} from "react";
import {AppContext} from "../../context/context";
import {darkTheme, desktop} from "../../utils/serviceVariables";

const Title = ({isReady}) => {
    const {theme, deviceMode} = useContext(AppContext);
    // Name container(for animation).
    const nameContainerRef = useRef();

    // Assigns an animation class for the name container when the component is ready.
    useEffect(() => {
        if (isReady) {
            nameContainerRef.current.className = stl.nameContainerAnimate;
        }
    }, [isReady])

    return (
        <div className={theme === darkTheme ? stl.main : [stl.main, stl.light].join(" ")}>
            <div className={deviceMode === desktop ? stl.titleContainer : [stl.titleContainer, stl.mobile].join(" ")}>
                <div ref={nameContainerRef}>
                    <div>
                        <span className={deviceMode === desktop ? [stl.name, stl.lastName].join(" ") : [stl.name, stl.lastName, stl.mobile].join(" ")}>{lastName}</span>
                        <span className={deviceMode === desktop ? [stl.name, stl.firstName].join(" ") : [stl.name, stl.firstName, stl.mobile].join(" ")}>{firstName}</span>
                    </div>
                    <span className={deviceMode === desktop ? stl.profession : [stl.profession, stl.mobile].join(" ")}
                          translate={"no"}>{profession}</span>
                </div>
            </div>
        </div>
    );
};

export default Title;