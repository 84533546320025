import React, {useContext, useRef} from 'react';
import st from "./PortfolioStackImage.module.css"
import {AppContext} from "../../../context/context";
import {darkTheme} from "../../../utils/serviceVariables";

const PortfolioStackImage = ({label, image}) => {
    const glareRef = useRef(null);
    const {theme} = useContext(AppContext);

    const showGlare = () => {
        glareRef.current.className = [st.glare, st.show].join(' ');
    }

    const hideGlare = () => {
        glareRef.current.className = st.glare;
    }

    return (
        <div className={theme === darkTheme ? st.main : [st.main, st.light].join(' ')} title={label} onMouseEnter={showGlare} onMouseLeave={hideGlare}>
            <div className={st.glare} ref={glareRef}/>
            <img className={st.icon} src={image} alt={label}/>
        </div>
    );
};

export default PortfolioStackImage;