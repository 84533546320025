import "./App.css"
import Title from "./components/title/Title";
import Resume from "./components/resume/Resume";
import Header from "./components/header/Header";
import {Background, Parallax} from "react-parallax";
import React, {useEffect, useRef, useState} from "react";
import {AppContext} from "./context/context";
import {desktop, deviceModeBorder, getDefaultTheme, mobile} from "./utils/serviceVariables";
import {scrollToPage} from "./utils/fustions";
import Stack from "./components/stack/Stack";
import Portfolio from "./components/portfolio/Portfolio";
import Contacts from "./components/contacts/Contacts";
import backgroundTitleImg from "./source/images/title-background.jpg";
import backgroundTitleMobileImg from "./source/images/title-background-mobile.jpg";
import backgroundStackImg from "./source/images/stack-background.jpg";
import backgroundStackMobileImg from "./source/images/stack-background-mobile.jpg";
import backgroundContactsImg from "./source/images/contacts-background.jpg";
import backgroundContactsMobileImg from "./source/images/contacts-background-mobile.jpg";
import {imgAlt} from "./source/content";
import FullScreenPhoto from "./components/full-screen-photo/FullScreenPhoto";
import fullScreenPhoto from "./components/full-screen-photo/FullScreenPhoto";


function App() {
    // Pages.
    const mainRef = useRef();
    const resumeRef = useRef();
    const stackRef = useRef();
    const portfolioRef = useRef();
    const contactsRef = useRef();
    const [currentPage, setCurrentPage] = useState(1);

    const [isFirstRender, setIsFirstRender] = useState(true);
    // App theme (dark or light)
    const [theme, setTheme] = useState(getDefaultTheme());
    // Device mode (mobile, desktop).
    const [deviceMode, setDeviceMode] = useState('')

    const [fullScreenPhoto, setFullScreenPhoto] = useState(null);

    // Initializes 'isMobile' state after first render;
    useEffect(() => {
        const interval = window.setInterval(() => {
            if (mainRef) {
                setDeviceMode(getDeviceMode());
            }
        }, 100)
        return () => clearInterval(interval);
    }, [])

    useEffect(() => {
        // After update scrolling page to start position (main)
        if (isFirstRender) {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
            setIsFirstRender(false)
        }
        // Listened main window size and switches 'isMobile' state.
        window.addEventListener('resize', () => setDeviceMode(getDeviceMode()));
        return () => window.removeEventListener('resize', () => setDeviceMode(getDeviceMode()));
    }, [])

    // Returns isMobile state value depending on the windows width.
    const getDeviceMode = () => {
        if (mainRef.current.offsetWidth >= deviceModeBorder) {
            return desktop;
        } else {
            return mobile;
        }
    }

    // Flag of manually change page.
    const [isNavbarClicked, setIsNavbarClicked] = useState(false);

    // Changes current page if user touched navbar button.
    const onNavbarClick = (pageNum) => {
        setCurrentPage(pageNum)
        setIsNavbarClicked(true);
        scrollToPage(pageNum, setIsNavbarClicked, mainRef, resumeRef, stackRef, portfolioRef, contactsRef)
    }

    // Listened scroll and changes current page which user scrolled.
    useEffect(() => {
        window.addEventListener('scroll', scrollActivity);
        return () => {
            window.removeEventListener('scroll', scrollActivity);
        };
    }, [isNavbarClicked])

    // Function then is executed when user scrolling page.
    const scrollActivity = () => {
        const headerIndent = 50;
        if (!isNavbarClicked) {
            if (mainRef.current.getBoundingClientRect().y === 0) {
                setCurrentPage(1)
            } else if (resumeRef.current.getBoundingClientRect().y <= headerIndent
                && resumeRef.current.getBoundingClientRect().y > 0) {
                setCurrentPage(2)
            } else if (stackRef.current.getBoundingClientRect().y <= headerIndent
                && stackRef.current.getBoundingClientRect().y > 0) {
                setCurrentPage(3)
            } else if (portfolioRef.current.getBoundingClientRect().y <= headerIndent
                && portfolioRef.current.getBoundingClientRect().y > 0) {
                setCurrentPage(4)
            } else if (contactsRef.current.getBoundingClientRect().y <= headerIndent
                && contactsRef.current.getBoundingClientRect().y > 0) {
                setCurrentPage(5)
            }
        }
    }

    // States for monitoring images loading progress.
    const [mainBackgroundImgIsLoaded, setMainBackgroundImgIsLoaded] = useState(false);
    const [resumeImgIsLoaded, setResumeImgIsLoaded] = useState(false);
    const [imagesLoaded, setImagesLoaded] = useState(false);

    // When all images are loaded removes the loader page and shows the app.
    useEffect(() => {
        if (mainBackgroundImgIsLoaded, resumeImgIsLoaded) {
            setImagesLoaded(true);
        }
    }, [mainBackgroundImgIsLoaded, resumeImgIsLoaded])


    return (
        <>
            <AppContext.Provider value={{theme, setTheme, deviceMode, currentPage, setFullScreenPhoto}}>
                <div className="appMain" ref={mainRef}>
                    {deviceMode !== '' &&
                        <>
                            <Header
                                currentPage={currentPage}
                                onNavbarClick={onNavbarClick}
                            />
                            <Parallax strength={400}>
                                <Background>
                                    <img
                                        onLoad={() => setMainBackgroundImgIsLoaded(true)}
                                        src={deviceMode === desktop ? backgroundTitleImg : backgroundTitleMobileImg}
                                        className="appBackground rightBottom"
                                        alt={imgAlt}/>
                                </Background>
                                <Title isReady={imagesLoaded}/>
                            </Parallax>
                            <Resume resumeRef={resumeRef} setImgIsLoaded={setResumeImgIsLoaded}/>
                            <Parallax strength={400}>
                                <Background>
                                    <img
                                        src={deviceMode === desktop ? backgroundStackImg : backgroundStackMobileImg}
                                        className="appBackground leftBottom"
                                        alt={imgAlt}/>
                                </Background>
                                <Stack stackRef={stackRef}/>
                            </Parallax>
                            <Portfolio portfolioRef={portfolioRef} appIsReady={imagesLoaded}/>
                            <Parallax strength={400}>
                                <Background>
                                    <img
                                        src={deviceMode === desktop ? backgroundContactsImg : backgroundContactsMobileImg}
                                        className="appBackground leftBottom"
                                        alt={imgAlt}/>
                                </Background>
                                <Contacts contactsRef={contactsRef}/>
                            </Parallax>
                        </>
                    }
                    <FullScreenPhoto photo={fullScreenPhoto} setPhoto={setFullScreenPhoto}/>
                </div>
            </AppContext.Provider>
            <div className={imagesLoaded ? "mainLoaderBackground notActive" : "mainLoaderBackground"}>
                <div className="mainLoader"/>
            </div>
        </>
    );
}

export default App;
