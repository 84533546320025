import React, {useEffect, useRef} from 'react';
import st from './StackTableItem.module.css';

const StackTableItem = ({label, image}) => {
    const mainRef = useRef(null);
    const glareRef = useRef(null);

    const showGlare = () => {
        glareRef.current.className = [st.glare, st.show].join(' ');
    }

    const hideGlare = () => {
        glareRef.current.className = st.glare;
    }

    useEffect(() => {
        const mainElement = mainRef.current;
        mainElement.addEventListener('mouseover', showGlare)
        mainElement.addEventListener('mouseleave', hideGlare)
        return () => {
            mainElement.removeEventListener('mouseover', showGlare)
            mainElement.removeEventListener('mouseleave', hideGlare)
        }
    }, [])

    return (
        <div className={st.main} ref={mainRef}>
            <div className={st.icon_container}>
                <div className={st.icon_inner_container}>
                    <div className={st.glare} ref={glareRef}/>
                    <img className={st.icon} src={image} alt={label}/>
                </div>
            </div>
            <div className={st.label}>
                {label}
            </div>
        </div>
    );
};

export default StackTableItem;