import React, {useContext, useEffect, useRef, useState} from 'react';
import stl from "./Stack.module.css";
import {backend, darkTheme} from "../../utils/serviceVariables";
import {AppContext} from "../../context/context";

const StackTable = ({stack}) => {
    const {theme} = useContext(AppContext);
    // Table with stack.
    const tableRef = useRef();
    // Flag will be true when tableRef appears on the screen first time.
    const [isVisible, setIsVisible] = useState(false);

    // Returned css classes for table cells.
    const getCellStyles = () => {
        if (isVisible) {
            if (stack.type === backend) {
                return theme === darkTheme ? [stl.regularTableCell, stl.visible].join(" ") : [stl.regularTableCell, stl.visible, stl.light].join(" ");
            } else {
                return theme === darkTheme ? [stl.regularTableCell, stl.visible, stl.frontend].join(" ") : [stl.regularTableCell, stl.visible, stl.frontend, stl.light].join(" ");
            }
        } else {
            return stl.regularTableCell;
        }
    }

    // Listens scroll and causes 'setIsVisibleByTablePosition'.
    useEffect(() => {
        if (!isVisible) {
            window.addEventListener('scroll', setIsVisibleByTablePosition)
            return () => window.removeEventListener('scroll', setIsVisibleByTablePosition)
        }
    }, [isVisible])

    // Makes 'isVisible' true when tableRef appears on the screen first time.
    const setIsVisibleByTablePosition = () => {
        if (tableRef.current.getBoundingClientRect().bottom < document.documentElement.clientHeight + 200) {
            setIsVisible(true);
        }
    }

    return (
        <table className={stl.table} ref={tableRef}>
            <tbody>
            {
                stack.stack.map(row =>
                    <tr key={row.id}>
                        <td className={getCellStyles()}>{row.row[0]}</td>
                        <td className={getCellStyles()}>{row.row[1]}</td>
                    </tr>
                )
            }
            </tbody>
        </table>
    );
};

export default StackTable;