import React, {useContext, useState} from 'react';
import stl from "./Stack.module.css";
import {backend, darkTheme, frontend} from "../../utils/serviceVariables";
import {AppContext} from "../../context/context";
import {getStackBackend, getStackButtons, getStackFrontend, stackTitle} from "../../source/content";
import StackTable from "./StackTable";

const Stack = ({stackRef}) => {
    const {theme} = useContext(AppContext);
    const [activeStack, setActiveStack] = useState(frontend);

    const getButtonStyle = (stack) => {
        if (activeStack === stack) {
            return [stl.button, stl.active].join(" ");
        } else {
            if (theme === darkTheme) {
                return stl.button;
            } else {
                return [stl.button, stl.light].join(" ");
            }
        }
    }

    return (
        <div className={theme === darkTheme ? stl.main : [stl.main, stl.light].join(" ")} ref={stackRef}>
            <div className={stl.halfScreenContainer}>
                <div className={theme === darkTheme ? stl.stackContainer : [stl.stackContainer, stl.light].join(" ")} translate={"no"}>
                    <span className={stl.title}>{stackTitle}</span>
                    <div className={stl.outerTableContainer}>
                        <div className={activeStack === backend ? stl.innerTableContainer : [stl.innerTableContainer, stl.frontend].join(" ")}>
                            <StackTable stack={getStackBackend()}/>
                            <StackTable stack={getStackFrontend()}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Stack;