// Themes
export const lightTheme = 'lightTheme';
export const darkTheme = 'darkTheme';

// Returns default theme
export const getDefaultTheme = () => {
    return darkTheme;
}

// Device modes
export const mobile = 'mobile';
export const desktop = 'desktop';

// Stack
export const backend = 'backend';
export const frontend = 'frontend';

// Contacts
export const sendMessageUrl = 'https://cefa49cd12d0.vps.myjino.ru/post-message';

// Device mode width border (px)
export const deviceModeBorder = 800;
