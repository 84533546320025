import React from 'react';

const ArrowBack = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 -960 960 960" width="40px" fill="orange">
            <path d="M400-80 0-480l400-400 61 61.67L122.67-480 461-141.67 400-80Z"/>
        </svg>
    );
};

export default ArrowBack;