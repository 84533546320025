import React, {useEffect, useState} from 'react';
import stl from "./UpButton.module.css"

const UpButton = ({isActive}) => {
    // Changes scroll Y position to 0.
    const toStartPosition = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }

    return (
        <div className={isActive ? stl.main : [stl.main, stl.hidden].join(" ")} onClick={toStartPosition}>
            <div className={stl.wand}/>
            <div className={stl.arrow}/>
        </div>
    );
};

export default UpButton;