import React, {useMemo} from 'react';
import st from './DownloadMessage.module.css';
import ArrowUp from "../../ui/icons/ArrowUp";

const DownloadMessage = ({isVisible, isStartPosition, flashingVisibleState, onClick}) => {
    const mainClassNames = useMemo(() => {
        if (isVisible) {
            return !isStartPosition ? st.main : [st.main, st.start_position].join(" ");
        } else {
            return !isStartPosition ? [st.main, st.not_visible].join(" ") : [st.main, st.not_visible, st.start_position].join(" ");
        }
    }, [isVisible, isStartPosition])

    return (
        <div className={mainClassNames} onClick={onClick}>
            <span>Скачать резюме</span>
            <div className={st.second_line_container}>
                <span>в формате PDF</span>
                <div className={flashingVisibleState ? [st.up_arrow_container, st.flashing].join(' ') : st.up_arrow_container}>
                    <ArrowUp/>
                </div>
            </div>
        </div>
    );
};

export default DownloadMessage;