import React from 'react';
import stl from "./PortfolioItem.module.css"
import {useEffect, useRef} from "react";
import {details, imgAlt} from "../../../source/content";
import {useSwipeable} from "react-swipeable";

const PortfolioItem = ({project, allProjects, currentProjectId, setIsMovies, detailsIsActive, setDetailsIsActive, chooseNextProject, chooseLastProject}) => {
    // Ref of main div of the portfolio item.
    const portfolioItemRef = useRef();

    // The carousel is stops when the mouse on current product's portfolio item.
    useEffect(() => {
        portfolioItemRef.current.onmouseenter = () => {
            setIsMovies(false);
        }
        portfolioItemRef.current.onmouseleave = () => {
            if (!detailsIsActive) {
                setIsMovies(true);
            }
        }
    }, [setIsMovies, detailsIsActive])

    // Returns css classes for move a portfolio item by its position.
    const getMoveItemStyle = () => {
        if (project.id === currentProjectId) {
            return [stl.main, stl.current].join(" ");
        } else if ((currentProjectId > 1 && project.id === currentProjectId - 1)
            || (currentProjectId === 1 && project.id === allProjects.length)) {
            return stl.main;
        } else if ((currentProjectId > 2 && project.id === currentProjectId - 2)
            || (currentProjectId === 2 && project.id === allProjects.length)
            || (currentProjectId === 1 && project.id === allProjects.length - 1)) {
            return [stl.main, stl.leftHidden].join(" ");
        } else if ((currentProjectId < allProjects.length && project.id === currentProjectId + 1)
            || (currentProjectId === allProjects.length && project.id === 1)) {
            return [stl.main, stl.rightVisible].join(" ");
        } else if ((currentProjectId < allProjects.length - 1 && project.id === currentProjectId + 2)
            || (currentProjectId === allProjects.length && project.id === 2)
            || (currentProjectId === allProjects.length - 1 && project.id === 1)) {
            return [stl.main, stl.rightHidden].join(" ");
        } else {
            return [stl.main, stl.hidden].join(" ");
        }
    }

    // Swipes portfolio item.
    const handlers = useSwipeable({
        onSwiped: (eventData) => {
            if (eventData.dir === 'Right') {
                chooseLastProject();
            } else {
                chooseNextProject();
            }
        }
    });

    return (
        <div className={getMoveItemStyle()} ref={portfolioItemRef}>
            <img src={project.image}
                 className={project.whiteBackground ? [stl.image, stl.border].join(" ") : stl.image}
                 onClick={() => setDetailsIsActive(true)}
                 title={details}
                 alt={imgAlt}
                 {...handlers}/>
        </div>
    );
};

export default PortfolioItem;