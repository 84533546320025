import React from 'react';
import stl from "./MobileNavbar.module.css"
import {useContext} from "react";
import {AppContext} from "../../../context/context";
import {darkTheme} from "../../../utils/serviceVariables";
import {getButtons} from "../../../source/content";

const MobileNavbar = ({isOpen, setIsOpen, onClick}) => {
    const {theme} = useContext(AppContext);

    const changeStatus = () => {
        setIsOpen(!isOpen)
    }

    const getFullScreenNavbarStyles = () => {
        if (isOpen) {
            return theme === darkTheme ? [stl.fullScreenNavbar, stl.isOpen].join(" ") : [stl.fullScreenNavbar, stl.isOpen, stl.light].join(" ");
        } else {
            return stl.fullScreenNavbar;
        }
    }

    const pageButtonClick = (pageId) => {
        setIsOpen(false);
        onClick(pageId);
    }

    return (
        <>
            <div className={getFullScreenNavbarStyles()}>
                {
                    getButtons().map(button =>
                        <div
                            key={button.id}
                            className={stl.pageButton}
                            onClick={() => pageButtonClick(button.id)}>
                            {button.title}
                        </div>
                    )
                }
            </div>
            <div className={stl.button} onClick={changeStatus}>
                <div className={stl.outerContainer}>
                    <div className={isOpen ? [stl.wand, stl.firstActive].join(" ") : [stl.wand, stl.firstNotActive].join(" ")}/>
                </div>
                <div className={stl.outerContainer}>
                    <div className={isOpen ? [stl.wand, stl.secondActive].join(" ") : [stl.wand, stl.secondNotActive].join(" ")}/>
                </div>
                <div className={stl.outerContainer}>
                    <div className={isOpen ? [stl.wand, stl.thirdActive].join(" ") : [stl.wand, stl.thirdNotActive].join(" ")}/>
                </div>
            </div>
        </>
    );
};

export default MobileNavbar;