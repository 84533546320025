import React from 'react';

const ArrowForward = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 -960 960 960" width="40px" fill="orange">
            <path d="m309.67-81.33-61-61.67L587-481.33 248.67-819.67l61-61.66 400 400-400 400Z"/>
        </svg>
    );
};

export default ArrowForward;