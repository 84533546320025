// Navbar
export const scrollToPage = (pageNum, setIsNavbarClicked, mainRef, resumeRef, stackRef, portfolioRef, contactsRef) => {
    switch (pageNum) {
        case 2:
            resumeRef.current.scrollIntoView({behavior: "smooth"})
            break;
        case 3:
            stackRef.current.scrollIntoView({behavior: "smooth"})
            break;
        case 4:
            portfolioRef.current.scrollIntoView({behavior: "smooth"})
            break;
        case 5:
            contactsRef.current.scrollIntoView({behavior: "smooth"})
            break;
        default:
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
            break;
    }
    setTimeout(() => {
        setIsNavbarClicked(false)
    }, 1000)
}

// Portfolio
export const compareProjectIds = (currentId, lastId, generalCount) => {
    if (lastId > currentId) {
        if (lastId === generalCount && currentId === 1) {
            return 1;
        } else {
            return -1;
        }
    } else if (lastId < currentId) {
        if (lastId === 1 && currentId === generalCount) {
            return -1;
        } else {
            return 1;
        }
    }
}