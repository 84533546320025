import React, {useContext, useState} from 'react';
import stl from "./ThemeSwitch.module.css"
import {AppContext} from "../../../context/context";
import {lightTheme, darkTheme, desktop} from "../../../utils/serviceVariables";

const ThemeSwitch = () => {
    // Global variable.
    const {theme, setTheme, deviceMode} = useContext(AppContext);
    // If this is true(only first render) animation don't work.
    const [isPageStart, setIsPageStart] = useState(true)

    // Change global app theme(dark, light)
    const changeTheme = () => {
        if (isPageStart) {
            setIsPageStart(false);
        }

        if (theme === lightTheme) {
            setTheme(darkTheme)
        } else {
            setTheme(lightTheme)
        }
    }

    // Get css classes for even sun rays.
    const getBackSquareClass = () => {
        if (isPageStart) {
            if (theme === darkTheme) {
                return stl.backSquareEven;
            } else {
                return [stl.backSquareEven, stl.notActive].join(" ");
            }
        } else {
            if (theme === darkTheme) {
                return [stl.backSquareEven, stl.active].join(" ");
            } else {
                return [stl.backSquareEven, stl.notActive, stl.animation].join(" ");
            }
        }
    }

    // Get css classes for odd sun rays.
    const getBackSquareRotateClass = () => {
        if (isPageStart) {
            if (theme === darkTheme) {
                return stl.backSquareOdd;
            } else {
                return [stl.backSquareOdd, stl.notActive].join(" ");
            }
        } else {
            if (theme === darkTheme) {
                return [stl.backSquareOdd, stl.active].join(" ");
            } else {
                return [stl.backSquareOdd, stl.notActive, stl.animation].join(" ");
            }
        }
    }

    // Get css classes for moon shadow.
    const getMoonShadowClass = () => {
        if (isPageStart) {
            if (theme === darkTheme) {
                return stl.moonShadow;
            } else {
                return [stl.moonShadow, stl.active].join(" ");
            }
        } else {
            if (theme === darkTheme) {
                return [stl.moonShadow, stl.notActive].join(" ");
            } else {
                return [stl.moonShadow, stl.active, stl.animation].join(" ");
            }
        }
    }

    return (
        <div className={deviceMode === desktop ? stl.main : [stl.main, stl.mobile].join(" ")} onClick={changeTheme}>
            {/* Horizontal even rays */}
            <div className={stl.itemContainer}>
                <div className={getBackSquareClass()}/>
            </div>
            {/* Vertical odd rays */}
            <div className={stl.itemContainer}>
                <div className={getBackSquareRotateClass()}/>
            </div>
            {/* Sun/moon body */}
            <div className={stl.itemContainer}>
                <div className={theme === darkTheme ? stl.center : [stl.center, stl.light].join(" ")}/>
            </div>
            {/* Moon shadow */}
            <div className={stl.itemContainer}>
                <div className={getMoonShadowClass()}/>
            </div>
        </div>
    );
};

export default ThemeSwitch;