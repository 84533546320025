import {backend, frontend} from "../utils/serviceVariables";
import StackTableItem from "../components/stack/stack-item/StackTableItem";
import jsImage from '../source/images/stack/js.png'
import reactImage from '../source/images/stack/react.png'
import axiosImage from '../source/images/stack/axios.png'
import cssImage from '../source/images/stack/css.png'
import htmlImage from '../source/images/stack/html.png'
import mobxImage from '../source/images/stack/mobx.png'
import nextImage from '../source/images/stack/next.png'
import nodeImage from '../source/images/stack/node.png'
import reactFlowImage from '../source/images/stack/react-flow.png'
import reduxImage from '../source/images/stack/redux.png'
import tsImage from '../source/images/stack/ts.png'
import postgresImage from '../source/images/stack/postgres.png'
import tailwindImage from '../source/images/stack/tw.png'
import gitImage from '../source/images/stack/git.png'
import sassImage from '../source/images/stack/sass.png'
import PortfolioStackImage from "../components/portfolio/stack-image/PortfolioStackImage";


// Header
export const headerName = "MESHALKIN IGOR";
export const downloadButtonTitle = "Скачать резюме (PDF)"

// Navbar
export const getButtons = () => {
    return [
        {id: 1, title: 'Главная'},
        {id: 2, title: 'Резюме'},
        {id: 3, title: 'Стек'},
        {id: 4, title: 'Портфолио'},
        {id: 5, title: 'Контакты'},
    ];
}

// Title
export const firstName = "Игорь";
export const lastName = "Мешалкин ";
export const profession = "Frontend developer";
// export const profession = "Java developer";

// Resume
export const resumeTitle = "Резюме";
export const resumeText1 = "Я Frontend разработчик с 2020 года. Ранее, в разные годы, \n" +
    "занимался продажей недвижимости и бизнесом в сфере \n" +
    "проектирования и монтажа инженерных коммуникаций.";
export const resumeText7 = "За годы работы программистом я принял участие \n" +
    "в нескольких разных проектах, благодаря чему не\n" +
    "только изучил различные технологии и подходы,\n" +
    "но и выработал навык быстро ориентироваться в незнакомой \n" +
    "обстановке, вникать в чужой код и находить оптимальные \n" +
    "решения. ";
export const resumeText8 = "Дисциплинирован и пунктуален. Умею работать самостоятельно,\n" +
    "но интересы команды держу в приоритете. Пишу понятный код. \n" +
    "Уверенно работаю с документацией, в том числе на английском языке. ";
export const resumeText9 = " там я занимался разработкой веб приложений для сбора и анализа медицинской статистики с последующим представлением этих данных в удобном заказчику виде.";

export const resumeText10 = "С июня 2023 по настоящее время";
export const resumeText11 = "Занимаюсь выполнением задач для разных стартапов и частных заказчиков.";
export const resumeText12 = "Я пишу понятный код и верстаю аккуратные страцицы. Умею вникать в чужой код, читать документацию , в том числе на английском, и находить простые, эффективные решения.";

// export const resumeTitle = "Резюме";
// export const resumeText1 = "Я Java разработчик c опытом работы 3+ года.";
// export const resumeText7 = "С декабря 2020 по июнь 2023";
// export const resumeText8 = "Я работал в ";
// export const resumeText9 = " там я занимался разработкой и сопровождением информационных систем для сбора, анализа и хранения медицинской статистики, а так же решал задачи связанные с отправкой этих данных в федеральные информационные системы.";
//
// export const resumeText10 = "С июня 2023 по настоящее время";
// export const resumeText11 = "Занимаюсь выполнением задач для разных стартапов и частных заказчиков.";
// // export const resumeText12 = "Я пишу понятный код и верстаю аккуратные страцицы. Умею вникать в чужой код, читать документацию , в том числе на английском, и находить простые, эффективные решения.";
// export const resumeText12 = "Я пишу понятный код. Умею работать с микросервисной архитектурой, вникать в чужой код, читать документацию , в том числе на английском, и находить простые, эффективные решения.";


export const miacLink = {
    text: "КГБУЗ \"Медицинский информационно аналитический центр Хабаровского края\"",
    link: "https://miac.medkhv.ru/"
};

// Stack
export const stackTitle = "Стек";
export const getStackButtons = () => {
    return [
        {id: 1, title: 'Backend', stack: backend},
        {id: 2, title: 'Frontend', stack: frontend}
    ];
}
// Content for backend stack table.
export const getStackBackend = () => {
    return {
        type: backend,
        stack: [
            {id: 1, row: ['Java', 'JDBC API']},
            {id: 2, row: ['Spring Core', 'Hibernate']},
            {id: 3, row: ['Spring Security', 'JUnit']},
            {id: 4, row: ['Spring Data JPA', 'Mockito']},
            {id: 5, row: ['SQL', 'Apache Maven']},
            {id: 6, row: ['NoSQL', 'Apache Kafka']},
        ]
    };
}

export const experience = [
    {
        id: 1,
        name: 'Медицинский информационно-аналитический центр Хабаровского края',
        period: '11.2020 - 06.2023',
        role: 'Инженер-программист',
        type: 'организация',
        responsibilities: 'Разработка веб приложений, форм медицинской статистики \n' +
            'и дашбордов для Министерства Здравоохранения Хабаровского\n' +
            'края. Интеграция с внешними API, отладка взаимодействия с бекендом.'
    },
    {
        id: 2,
        name: 'Behoof',
        period: '08.2023 - 02.2024',
        role: 'Frontend разработчик',
        type: 'проект',
        responsibilities: 'Разработка веб приложения для сервиса сравнения и поиска товаров, ориентированного на Латинскую Америку, а так же разработка веб приложения-админки для основного сервиса.'
    },
    {
        id: 3,
        name: 'Storage Table',
        period: '05.2024 - 09.2024',
        role: 'Frontend разработчик',
        type: 'проект',
        responsibilities: 'Участие в разработке веб приложения для сервиса управления базами данных, а именно работа над модулем включающим в себя: визуальное представление схемы БД, работающее с drag & drop логикой, табличное представление данных и SQL Editor для отправки запросов.'
    }
]


// Content for frontend stack table.
export const getStackFrontend = () => {
    return {
        type: frontend,
        stack: [
            {id: 1,
                row: [<StackTableItem label={'JavaScript'} image={jsImage}/>,
                    <StackTableItem label={'HTML'} image={htmlImage}/>]
            },
            {id: 2,
                row: [<StackTableItem label={'TypeScript'} image={tsImage}/>,
                    <StackTableItem label={'CSS'} image={cssImage}/>]
            },
            {id: 3,
                row: [<StackTableItem label={'React'} image={reactImage}/>,
                    <StackTableItem label={'SASS'} image={sassImage}/>]
            },
            {id: 4,
                row: [<StackTableItem label={'Next.js'} image={nextImage}/>,
                    <StackTableItem label={'Tailwind'} image={tailwindImage}/>]
            },
            {id: 5,
                row: [<StackTableItem label={'Redux'} image={reduxImage}/>,
                    <StackTableItem label={'Axios'} image={axiosImage}/>]
            },
            {id: 6,
                row: [<StackTableItem label={'Mobx'} image={mobxImage}/>,
                    <StackTableItem label={'Node.js'} image={nodeImage}/>]
            },
            {id: 7,
                row: [<StackTableItem label={'React Flow'} image={reactFlowImage}/>,
                    <StackTableItem label={'PostgreSQL'} image={postgresImage}/>]
            },
        ]
    };
}

// Portfolio
export const portfolioTitle = "Портфолио";
export const getProjects = (images) => {
    return [
        {
            id: 1,
            image: images[0],
            name: "Behoof",
            description: "Сервис для поиска и сравнения товаров по множеству параметров которые выбирает сам пользователь.",
            team: ["5 человек:", "- Teamlead", "- Backend разработчик", "- Frontend разработчик", "- Мобильный разработчик", "- QA Engineer"],
            role: "Frontend разработчик",
            achievements: "",
            stack: [
                <PortfolioStackImage label={'TypeScript'} image={tsImage}/>,
                <PortfolioStackImage label={'HTML'} image={htmlImage}/>,
                <PortfolioStackImage label={'CSS'} image={cssImage}/>,
                <PortfolioStackImage label={'Tailwind'} image={tailwindImage}/>,
                <PortfolioStackImage label={'NextJS'} image={nextImage}/>,
                <PortfolioStackImage label={'Redux'} image={reduxImage}/>,
                <PortfolioStackImage label={'Axios'} image={axiosImage}/>,
            ],
            whiteBackground: true,
            link: {
                title: "Перейти к приложению",
                link: "https://behoof.com.mx/"
            }
        },
        {
            id: 2,
            image: images[1],
            name: "Основные демографические показатели в Хабаровском крае",
            description: "Сервис для сбора и демонстрации статистических данных по смертности и рождаемости в Хабаровском крае. Данные обновляются с помощью специальной формы, которая доступна авторизованному пользователю.",
            team: ["2 человека:", "- Руководидель отдела программного обеспечения", "- Fullstack разработчик"],
            role: "Fullstack разработчик",
            achievements: "",
            stack: [
                <PortfolioStackImage label={'JavaScript'} image={jsImage}/>,
                <PortfolioStackImage label={'HTML'} image={htmlImage}/>,
                <PortfolioStackImage label={'CSS'} image={cssImage}/>,
                <PortfolioStackImage label={'React'} image={reactImage}/>,
                <PortfolioStackImage label={'Axios'} image={axiosImage}/>,
                <PortfolioStackImage label={'NodeJS'} image={nodeImage}/>,
                <PortfolioStackImage label={'Postgres'} image={postgresImage}/>,
            ],
            whiteBackground: false,
            link: {
                title: "Перейти к приложению",
                link: "https://basic-dmg.medkhv.ru/"
            }
        },
        {
            id: 3,
            image: images[2],
            name: "Sims Dynasty Tree",
            description: "Сервис для построения дерева династии по игре Sims и общения с друзьями по игре.",
            team: ["5 человек:", "- Teamlead", "- Backend разработчик", "- два Frontend разработчика", "- QA Engineer"],
            role: "Frontend разработчик",
            achievements: "Нашёл способ добавить кастомные emoji в библиотеку которая этого не подразумевала, чем обеспечил заказчику возможность включить в платныую подписку дополнительные emoji.",
            stack: [
                <PortfolioStackImage label={'TypeScript'} image={tsImage}/>,
                <PortfolioStackImage label={'HTML'} image={htmlImage}/>,
                <PortfolioStackImage label={'CSS'} image={cssImage}/>,
                <PortfolioStackImage label={'SASS'} image={sassImage}/>,
                <PortfolioStackImage label={'NextJS'} image={nextImage}/>,
                <PortfolioStackImage label={'ReactFlow'} image={reactFlowImage}/>,
                <PortfolioStackImage label={'Mobx'} image={mobxImage}/>,
                <PortfolioStackImage label={'Axios'} image={axiosImage}/>,
            ],
            whiteBackground: true,
            link: {
                title: "Перейти к приложению",
                link: "https://simsdynastytree.com/"
            }
        },
        {
            id: 4,
            image: images[4],
            name: "Storage table",
            description: "Сервис который делает работу с базами данных простой и интуитивно понятной. На данный момент продукт находится на стадии MVP поэтому я не могу предоставить ссылку.",
            team: ["3 человека:", "- Teamlead", "- два Frontend разработчика"],
            role: "Frontend разработчик",
            achievements: "Сумел добиться стабильной совместной работы библиотек ReactFlow и React beautiful DnD с целью перетягивания новых строк для таблиц из меню редактирования прямо в рабочую область React Flow, а так же изменения порядка этих строк в дальнейшем.",
            stack: [
                <PortfolioStackImage label={'TypeScript'} image={tsImage}/>,
                <PortfolioStackImage label={'HTML'} image={htmlImage}/>,
                <PortfolioStackImage label={'CSS'} image={cssImage}/>,
                <PortfolioStackImage label={'SASS'} image={sassImage}/>,
                <PortfolioStackImage label={'React'} image={reactImage}/>,
                <PortfolioStackImage label={'Redux'} image={reduxImage}/>,
                <PortfolioStackImage label={'ReactFlow'} image={reactFlowImage}/>,
                <PortfolioStackImage label={'Axios'} image={axiosImage}/>,
            ],
            whiteBackground: false,
        },
        {
            id: 5,
            image: images[3],
            name: "Лендинг риэлтора",
            description: "Продающая страница для риэлтора из Хабаровска. Основная цель - получить входящую заявку.",
            team: ["Самостоятельная работа непосредственно с заказчиком."],
            role: "Frontend разработчик",
            whiteBackground: false,
            stack: [
                <PortfolioStackImage label={'JavaScript'} image={jsImage}/>,
                <PortfolioStackImage label={'HTML'} image={htmlImage}/>,
                <PortfolioStackImage label={'CSS'} image={cssImage}/>,
                <PortfolioStackImage label={'React'} image={reactImage}/>
            ],
            link: {
                title: "Перейти к приложению",
                link: "https://александр27.рф"
            }
        },
        {
            id: 6,
            image: images[5],
            name: "WhyWhy Analytics",
            description: "Сервис для проведения анализа по методике \"5 почему\". По желанию заказчика сервис выполнен в минималистичном стиле.",
            team: ["Самостоятельная работа непосредственно с заказчиком."],
            role: "Frontend разработчик",
            stack: [
                <PortfolioStackImage label={'TypeScript'} image={tsImage}/>,
                <PortfolioStackImage label={'HTML'} image={htmlImage}/>,
                <PortfolioStackImage label={'CSS'} image={cssImage}/>,
                <PortfolioStackImage label={'SASS'} image={sassImage}/>,
                <PortfolioStackImage label={'React'} image={reactImage}/>,
                <PortfolioStackImage label={'Redux'} image={reduxImage}/>,
            ],
            whiteBackground: true,
            link: {
                title: "Перейти к приложению",
                link: "https://whywhy.online/"
            }
        }
    ];
}

// Contacts
export const contactsTitle = "Связаться со мной";
export const yourName = "Ваше имя";
export const feedback = "Ваш email, Telegram, телефон или другое. ";
export const message = "Сообщение...";
export const submitButtonText = "Отправить";
export const fetchSuccess = 'Сообщение успешно отправлено. Я свяжусь с вами в ближайшее время.'
export const fetchFail = 'Не удалось отправить сообщение. Повторите попытку позже.'
export const photoBy = 'Photo by:';
export const photographers = [
    {
        name: 'Daria Borisova',
        link: 'https://t.me/dasha_bori'
    },
    {
        name: 'Anna Shvets',
        link: 'https://www.pexels.com/ru-ru/photo/12662883/'
    }
]

// System messages
export const details = "Подробнее";
export const imgAlt = "Не удалось загрузить изображение";