import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import stl from "./Header.module.css"
import {headerName} from "../../source/content";
import ThemeSwitch from "../ui/switch/ThemeSwitch";
import {AppContext} from "../../context/context";
import {darkTheme} from "../../utils/serviceVariables";
import Navbar from "../navbar/Navbar";
import UpButton from "../ui/up-button/UpButton";
import DownloadButton from "../ui/download-button/DownloadButton";
import DownloadMessage from "./download-message/DownloadMessage";

const Header = ({currentPage, onNavbarClick}) => {
    // Global variable.
    const {theme} = useContext(AppContext);
    // Scroll Y has zero position?
    const [isStartPosition, setIsStartPosition] = useState(true)
    // Is mobile navbar (full screen) is open.
    const [mobileNavbarIsOpen, setMobileNavbarIsOpen] = useState(false)
    // Is download message block already was showed or not.
    const [downloadMessageWasShowed, setDownloadMessageWasShowed] = useState(false)
    // Is download message block visible.
    const [isDownloadMessageVisible, setIsDownloadMessageVisible] = useState(false)
    // Download buttons flashing visible
    const [downloadButtonsFlashing, setDownloadButtonsFlashing] = useState(false);

    // Switches 'isStartPosition' state.
    const switchStartPosition = () => {
        if (window.scrollY === 0) {
            setIsStartPosition(true)
        } else {
            setIsStartPosition(false)
        }
    };

    // Listens scroll and switches 'isStartPosition' state.
    useEffect(() => {
        window.addEventListener("scroll", switchStartPosition);
        return () => window.removeEventListener("scroll", switchStartPosition);
    }, [])

    useEffect(() => {
        if (!isStartPosition && !downloadMessageWasShowed) {
            setIsDownloadMessageVisible(true);
            setIsDownloadMessageVisible(true);
            setDownloadMessageWasShowed(true);
        }
    }, [downloadMessageWasShowed, isStartPosition]);

    useEffect(() => {
        if (isDownloadMessageVisible) {
            const downloadMessageTimeout = setTimeout(() => {
                setIsDownloadMessageVisible(false);
            }, 5000)
            const downloadButtonsFlashingInterval = setInterval(() => {
                setDownloadButtonsFlashing(prevState => !prevState);
            }, 600)
            return () => {
                clearTimeout(downloadMessageTimeout);
                clearInterval(downloadButtonsFlashingInterval)
            }
        }
    }, [isDownloadMessageVisible])

    // Get css style for main div.
    const getMainClass = () => {
        if (isStartPosition) {
            return theme === darkTheme ? [stl.main, stl.darkTheme, stl.startPosition].join(" ") : [stl.main, stl.lightTheme, stl.startPosition].join(" ");
        } else {
            return theme === darkTheme ? [stl.main, stl.darkTheme].join(" ") : [stl.main, stl.lightTheme].join(" ");
        }
    }

    return (
        <div className={getMainClass()}>
            <div className={theme === darkTheme ? stl.name : [stl.name, stl.darkTheme].join(" ")}
                 translate={"no"}>{headerName}</div>
            <div className={stl.reverseContainer}>
                <div className={stl.navbarContainer}>
                    <Navbar
                        currentPage={currentPage}
                        onNavbarClick={onNavbarClick}
                        mobileNavbarIsOpen={mobileNavbarIsOpen}
                        setMobileNavbarIsOpen={setMobileNavbarIsOpen}
                    />
                </div>
                <div className={[stl.buttonContainer, stl.switch].join(" ")}>
                    <ThemeSwitch/>
                </div>
                <div className={[stl.buttonContainer, stl.download].join(" ")}>
                    <DownloadButton flashingVisibleState={downloadButtonsFlashing} isMessageVisible={isDownloadMessageVisible} isStartPosition={isStartPosition}/>
                </div>
            </div>
            <UpButton isActive={mobileNavbarIsOpen ? false : !isStartPosition}/>
        </div>
    );
};

export default Header;