import React, {useEffect, useMemo, useRef} from 'react';
import stl from "./DownloadButton.module.css"
import {downloadButtonTitle} from "../../../source/content";
import resumeFile from "../../../source/Мешалкин Игорь. Резюме.pdf"
import Download from "../icons/Download";
import DownloadMessage from "../../header/download-message/DownloadMessage";

const DownloadButton = ({flashingVisibleState, isMessageVisible, isStartPosition}) => {

    const linkRef = useRef(null);

    const onMessageClick = () => {
        linkRef.current.click();
    }

    return (
        <>
            <a href={resumeFile} className={flashingVisibleState ? [stl.main, stl.flashing].join(' ') : stl.main}
               title={downloadButtonTitle} ref={linkRef} download>
                <Download/>
            </a>
            <DownloadMessage isVisible={isMessageVisible} isStartPosition={isStartPosition}
                             flashingVisibleState={flashingVisibleState} onClick={onMessageClick}/>
        </>
    );
};

export default DownloadButton;