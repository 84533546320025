import React, {useContext, useEffect, useRef, useState} from 'react';
import stl from "./PortfolioDetails.module.css";
import {AppContext} from "../../../context/context";
import {darkTheme, desktop, mobile} from "../../../utils/serviceVariables";
import {imgAlt} from "../../../source/content";
import CloseButton from "../../ui/close-button/CloseButton";
import {useSwipeable} from "react-swipeable";
import {compareProjectIds} from "../../../utils/fustions";
import FullScreenPhoto from "../../full-screen-photo/FullScreenPhoto";
import ArrowBack from "../../ui/icons/ArrowBack";
import ArrowForward from "../../ui/icons/ArrowForward";

const PortfolioDetails = ({
                              project,
                              allProjects,
                              currentProjectId,
                              setCurrentProjectId,
                              isActive,
                              setIsActive,
                              chooseNextProject,
                              chooseLastProject
                          }) => {

    const {theme, deviceMode, setFullScreenPhoto} = useContext(AppContext);

    // The page element refs.
    const mainRef = useRef();
    const imageRef = useRef();
    const titleRef = useRef();
    const descriptionRef = useRef();

    // ID project which was selected last time(for animation direction)
    const [lastProjectId, setLastProjectId] = useState(currentProjectId);

    // Returns css classes for the main div of the page.
    const getMainStyle = () => {
        if (isActive) {
            return theme === darkTheme ? [stl.main, stl.active, stl.darkTheme].join(" ") : [stl.main, stl.active, stl.lightTheme].join(" ");
        } else {
            return stl.main;
        }
    }

    // If details page is open general scroll don't work.
    useEffect(() => {
        if (isActive) {
            document.body.style.overflowY = 'hidden';
        } else {
            document.body.style.overflowY = 'scroll';
        }
    }, [isActive])

    // Updates css classes(animation) if user select new project.
    useEffect(() => {
        if (isActive) {
            if (deviceMode === desktop) {
                imageRef.current.className = [stl.image, stl.imageAnimate].join(" ");
                titleRef.current.className = theme === darkTheme ? [stl.title, stl.regularAnimate].join(" ") : [stl.title, stl.light, stl.regularAnimate].join(" ");
                descriptionRef.current.className = theme === darkTheme ? [stl.infoContainer, stl.regularAnimate].join(" ") : [stl.infoContainer, stl.light, stl.regularAnimate].join(" ")
            } else {
                const swipeAnimationClass = (compareProjectIds(currentProjectId, lastProjectId, allProjects.length) > 0) ? stl.leftSwipeAnimate : stl.rightSwipeAnimate;
                imageRef.current.className = [stl.image, swipeAnimationClass].join(" ");
                titleRef.current.className = theme === darkTheme ? [stl.title, swipeAnimationClass].join(" ") : [stl.title, stl.light, swipeAnimationClass].join(" ");
                descriptionRef.current.className = theme === darkTheme ? [stl.infoContainer, swipeAnimationClass].join(" ") : [stl.infoContainer, stl.light, swipeAnimationClass].join(" ");
                setLastProjectId(currentProjectId);
            }
            const timeout = setTimeout(() => {
                imageRef.current.className = project.whiteBackground ? [stl.image, stl.border].join(" ") : stl.image;
                titleRef.current.className = theme === darkTheme ? stl.title : [stl.title, stl.light].join(" ");
                descriptionRef.current.className = theme === darkTheme ? stl.infoContainer : [stl.infoContainer, stl.light].join(" ");
            }, 500)
            return () => clearTimeout(timeout);
        }
    }, [currentProjectId])

    // Swipes projects.
    const handlers = useSwipeable({
        // onSwiped: (eventData) => {
        //     if (eventData.dir === 'Right') {
        //         chooseLastProject();
        //     } else {
        //         chooseNextProject();
        //     }
        // }
    });

    return (
        <div className={getMainStyle()} ref={mainRef} {...handlers}>
            <div className={stl.currentProjectContainer}>
                <div className={stl.imgContainer}>
                    <img src={project.image}
                         onClick={() => setFullScreenPhoto(project.image)}
                         className={project.whiteBackground ? [stl.image, stl.border].join(" ") : stl.image}
                         alt={imgAlt} ref={imageRef}/>
                </div>
                <div className={stl.infoContainer} ref={descriptionRef}>
                    <div className={theme === darkTheme ? stl.title : [stl.title, stl.light].join(" ")} ref={titleRef}>
                        {project.name}
                    </div>
                    <div className={stl.scrollArea}>
                        <div className={stl.paragragh}>
                            <div
                                className={theme === darkTheme ? stl.regularText : [stl.regularText, stl.light].join(" ")}>
                                {project.description}
                            </div>
                        </div>
                        <div className={stl.paragragh}>
                            <span
                                className={theme === darkTheme ? stl.miniTitle : [stl.miniTitle, stl.light].join(' ')}>состав команды</span>
                            {
                                project.team.map((item, key) =>
                                    <div
                                        key={key}
                                        className={theme === darkTheme ? stl.regularText : [stl.regularText, stl.light].join(" ")}>
                                        {item}
                                    </div>
                                )
                            }
                        </div>
                        <div className={stl.paragragh}>
                            <span
                                className={theme === darkTheme ? stl.miniTitle : [stl.miniTitle, stl.light].join(' ')}>моя роль</span>
                            <div
                                className={theme === darkTheme ? stl.regularText : [stl.regularText, stl.light].join(" ")}>
                                {project.role}
                            </div>
                        </div>
                        {
                            project.achievements &&
                            <div className={stl.paragragh}>
                            <span
                                className={theme === darkTheme ? stl.miniTitle : [stl.miniTitle, stl.light].join(' ')}>достижения</span>
                                <div
                                    className={theme === darkTheme ? stl.regularText : [stl.regularText, stl.light].join(" ")}>
                                    {project.achievements}
                                </div>
                            </div>
                        }
                        <div className={stl.paragragh}>
                            <span
                                className={theme === darkTheme ? stl.miniTitle : [stl.miniTitle, stl.light].join(' ')}>стек</span>
                            <div className={stl.stackContainer}>
                                {project.stack}
                            </div>
                        </div>
                    </div>
                    {
                        project.link &&
                        <a href={project.link.link}
                           className={theme === darkTheme ? stl.link : [stl.link, stl.light].join(" ")}>
                            {project.link.title}
                        </a>
                    }
                </div>
                <div className={stl.closeButtonOuterContainer}>
                    {
                        deviceMode === mobile &&
                        <div className={stl.chooseProjectButton} onClick={() => chooseLastProject()}>
                            <ArrowBack/>
                        </div>
                    }
                    <div className={stl.closeButtonInnerContainer}>
                        <CloseButton onClick={() => setIsActive(false)}/>
                    </div>
                    {
                        deviceMode === mobile &&
                        <div className={stl.chooseProjectButton} onClick={() => chooseNextProject()}>
                            <ArrowForward/>
                        </div>
                    }
                </div>
            </div>
            {
                deviceMode === desktop &&
                <div className={stl.chooseProjectBarContainer}>
                    {
                        allProjects.map(proj =>
                            <img
                                key={proj.id}
                                src={proj.image}
                                alt={imgAlt}
                                title={proj.name}
                                className={proj.id === project.id ? [stl.chooseProjectItem, stl.selected].join(" ") : stl.chooseProjectItem}
                                onClick={() => setCurrentProjectId(proj.id)}
                            />
                        )
                    }
                </div>
            }
        </div>
    );
};

export default PortfolioDetails;