import React, {useContext} from 'react';
import st from './FullScreenPhoto.module.css'
import CloseButton from "../ui/close-button/CloseButton";
import {AppContext} from "../../context/context";
import {lightTheme} from "../../utils/serviceVariables";

const FullScreenPhoto = ({photo, setPhoto}) => {
    const {theme} = useContext(AppContext)

    const getCloseButtonContainerClassNames = () => {
        const result = [st.closeButtonContainer]
        if (theme === lightTheme) {
            result.push(st.light)
        }
        if (photo) {
            result.push(st.active)
        }
        return result.join(' ');
    }

    return (
        <div className={photo ? [st.main, st.action].join(' ') : st.main} onDoubleClick={() => setPhoto(null)}>
            <img src={photo} className={photo ? [st.image, st.action].join(' ') : st.image} alt={'Фото на весь экран'}/>
            <div className={getCloseButtonContainerClassNames()}>
                <CloseButton onClick={() => setPhoto(null)}/>
            </div>
        </div>
    );
};

export default FullScreenPhoto;
