import React, {useContext, useEffect, useRef, useState} from 'react';
import stl from "./Portfolio.module.css"
import {AppContext} from "../../context/context";
import {darkTheme} from "../../utils/serviceVariables";
import projectImage1 from "../../source/images/projects/1.png"
import projectImage2 from "../../source/images/projects/2.png"
import projectImage3 from "../../source/images/projects/3.png"
import projectImage4 from "../../source/images/projects/4.png"
import projectImage5 from "../../source/images/projects/5.png"
import projectImage6 from "../../source/images/projects/6.png"
import {getProjects, portfolioTitle} from "../../source/content";
import PortfolioItem from "./item/PortfolioItem";
import PortfolioDetails from "./details/PortfolioDetails";

const imagesSrc = [projectImage1, projectImage2, projectImage3, projectImage4, projectImage5, projectImage6];

const Portfolio = ({portfolioRef, appIsReady}) => {
    const {theme, currentPage} = useContext(AppContext);
    // Area for points for change current project.
    const handlersRef = useRef();
    // All projects list.
    const [allProjects] = useState(getProjects([projectImage1, projectImage2, projectImage3, projectImage4, projectImage6, projectImage5]))
    // ID of the project currently selected.
    const [currentProjectId, setCurrentProjectId] = useState(1);
    // Carousel is moving now?
    const [isMoves, setIsMoves] = useState(true);
    // Window with details of current project open now?
    const [detailsIsActive, setDetailsIsActive] = useState(false);

    // The carousel stops when mouse on handles(points)
    useEffect(() => {
        handlersRef.current.onmouseenter = () => {
            setIsMoves(false);
        }
        handlersRef.current.onmouseleave = () => {
            setIsMoves(true);
        }
    }, [])

    // When app is ready loads all portfolio images straightaway.
    useEffect(() => {
        if (appIsReady) {
            imagesSrc.forEach(image => {
                (new Image()).src = image;
            })
        }
    }, [appIsReady])

    // The carousel starts when the 'isMovie' state changes to true.
    useEffect(() => {
        if (isMoves) {
            chooseNextProject();
            const interval = setInterval(chooseNextProject, 3000);
            return () => clearInterval(interval);
        }
    }, [isMoves])

    // The window with details of the current project closes if user change another page.
    useEffect(() => {
        setDetailsIsActive(false);
    }, [currentPage])

    // The carousel doesn't move when the details window is open.
    useEffect(() => {
        if (detailsIsActive) {
            setIsMoves(false);
        } else {
            setIsMoves(true);
        }
    }, [detailsIsActive])

    // Function of choose next project.
    const chooseNextProject = () => {
        setCurrentProjectId(prevState => prevState === allProjects.length ? 1 : prevState + 1)
    }

    // Function of choose last project.
    const chooseLastProject = () => {
        setCurrentProjectId(prevState => prevState === 1 ? allProjects.length : prevState - 1)
    }

    return (
        <div className={theme === darkTheme ? stl.main : [stl.main, stl.light].join(" ")} ref={portfolioRef}>
            <div className={stl.portfolioViewer}>
                <span className={stl.title}>{portfolioTitle}</span>
                <div className={stl.moveWindow}>
                    {
                        allProjects.map(project =>
                            <PortfolioItem
                                key={project.id}
                                project={project}
                                allProjects={allProjects}
                                currentProjectId={currentProjectId}
                                setIsMovies={setIsMoves}
                                detailsIsActive={detailsIsActive}
                                setDetailsIsActive={setDetailsIsActive}
                                chooseNextProject={chooseNextProject}
                                chooseLastProject={chooseLastProject}
                            />)
                    }
                </div>
                <div className={stl.bottomSideContainer}>
                    <div className={stl.handlersContainer} ref={handlersRef}>
                        {
                            allProjects.map(project =>
                                <div
                                    key={project.id}
                                    className={project.id === currentProjectId ? [stl.handler, stl.selected].join(" ") : stl.handler}
                                    onClick={() => setCurrentProjectId(project.id)}
                                />
                            )
                        }
                    </div>
                </div>
            </div>
            <PortfolioDetails
                project={allProjects[currentProjectId - 1]}
                allProjects={allProjects}
                currentProjectId={currentProjectId}
                setCurrentProjectId={setCurrentProjectId}
                isActive={detailsIsActive}
                setIsActive={setDetailsIsActive}
                chooseNextProject={chooseNextProject}
                chooseLastProject={chooseLastProject}
            />
        </div>);
};

export default Portfolio;