import React, {useContext, useState} from 'react';
import st from './Experience.module.css'
import {experience} from "../../../source/content";
import {AppContext} from "../../../context/context";
import {darkTheme, desktop} from "../../../utils/serviceVariables";

const Experience = () => {
    const {deviceMode, theme} = useContext(AppContext);

    const [selectedItem, setSelectedItem] = useState(null);

    const getItemClassNames = (id) => {
        const classNames = [st.itemBody];
        if (selectedItem && deviceMode === desktop) {
            selectedItem === id ? classNames.push(st.selected) : classNames.push(st.unselected);
        }
        return classNames.join(' ');
    }

    return (
        <div className={st.main}>
            <span className={st.title}>Опыт работы</span>
            <div className={st.scrollContainer}>
                {
                    experience.map(item =>
                        <div className={getItemClassNames(item.id)} key={item.id} onMouseLeave={() => setSelectedItem(null)}>
                            <div className={st.itemContentContainer} onMouseEnter={() => setSelectedItem(item.id)}>
                                <div className={st.contentRow}>
                                    <span className={theme === darkTheme ? st.role : [st.role, st.light].join(' ')}>{item.role}</span>
                                </div>
                                <div className={st.contentRow}>
                                    <span
                                        className={theme === darkTheme ? st.miniTitle : [st.miniTitle, st.light].join(' ')}>{item.type}</span>
                                    <span className={theme === darkTheme ? st.text : [st.text, st.light].join(' ')}>{item.name}</span>
                                </div>
                                <div className={st.contentRow}>
                                    <span
                                        className={theme === darkTheme ? st.miniTitle : [st.miniTitle, st.light].join(' ')}>обязанности</span>
                                    <span className={theme === darkTheme ? st.text : [st.text, st.light].join(' ')}>{item.responsibilities}</span>
                                </div>
                            </div>
                            <div className={st.period}>
                                {item.period}
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default Experience;