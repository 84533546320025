import React, {useContext} from 'react';
import {AppContext} from "../../context/context";
import stl from "./Navbar.module.css";
import {darkTheme, mobile} from "../../utils/serviceVariables";
import {getButtons} from "../../source/content";
import MobileNavbar from "./mobile/MobileNavbar";

const Navbar = ({currentPage, onNavbarClick, mobileNavbarIsOpen, setMobileNavbarIsOpen}) => {
    const {theme, deviceMode} = useContext(AppContext);

    const getDesktopButtonClass = (id) => {
        if (id === currentPage) {
            if (theme === darkTheme) {
                return [stl.deskTopButton, stl.selected].join(" ");
            } else {
                return [stl.deskTopButton, stl.selected, stl.light].join(" ");
            }
        } else {
            if (theme === darkTheme) {
                return stl.deskTopButton;
            } else {
                return [stl.deskTopButton, stl.light].join(" ");
            }
        }
    }

    return (
        <>
            {
                deviceMode !== "" &&
                <>
                    {
                        deviceMode === mobile ?
                            <div className={stl.main}>
                                <MobileNavbar
                                    isOpen={mobileNavbarIsOpen}
                                    setIsOpen={setMobileNavbarIsOpen}
                                    onClick={onNavbarClick}
                                />
                            </div> :
                            <div className={stl.main}>
                                {
                                    getButtons().map(button =>
                                        <div
                                            key={button.id}
                                            className={getDesktopButtonClass(button.id)}
                                            onClick={() => onNavbarClick(button.id)}>
                                            {button.title}
                                        </div>
                                    )
                                }
                            </div>
                    }
                </>
            }
        </>
    );
};

export default Navbar;