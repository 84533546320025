import React, {useContext, useEffect, useRef, useState} from 'react';
import stl from "./Contacts.module.css";
import {AppContext} from "../../context/context";
import {darkTheme, sendMessageUrl} from "../../utils/serviceVariables";
import {
    contactsTitle,
    feedback,
    fetchFail,
    fetchSuccess,
    message,
    photoBy,
    photographers,
    submitButtonText,
    yourName
} from "../../source/content";

const Contacts = ({contactsRef}) => {
    const {theme} = useContext(AppContext);

    const nameRef = useRef();
    const feedBackRef = useRef();
    const messageRef = useRef();
    const submitButtonRef = useRef();
    const resultMessageRef = useRef();

    const [isFetching, setIsFetching] = useState(false);
    const [fetchResultMessage, setFetchResultMessage] = useState('');

    const submit = () => {
        if (feedBackRef.current.value.length === 0 || messageRef.current.value.length === 0) {
            alertBorder();
        } else {
            setIsFetching(true);
            fetch(sendMessageUrl, {
                method: 'POST',
                referrerPolicy: "unsafe-url",
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Host': sendMessageUrl
                }, body: JSON.stringify({
                    name: nameRef.current.value,
                    contact: feedBackRef.current.value,
                    body: messageRef.current.value + ' [Java]'
                })
            }).then((res) => {
                if (res.status === 200) {
                    nameRef.current.value = '';
                    feedBackRef.current.value = '';
                    messageRef.current.value = '';
                    setFetchResultMessage(fetchSuccess);
                } else {
                    setFetchResultMessage(fetchFail);
                }
            }).catch(() => setFetchResultMessage(fetchFail))
                .finally(() => {
                    openCloseFetchResultMessageArea();
                    setIsFetching(false)
                });
        }
    }

    const alertBorder = () => {
        if (feedBackRef.current.value.length === 0) {
            feedBackRef.current.className = theme === darkTheme ? [stl.input, stl.alert].join(" ") : [stl.input, stl.light, stl.alert].join(" ");
        }
        if (messageRef.current.value.length === 0) {
            messageRef.current.className = theme === darkTheme ? [stl.input, stl.textArea, stl.alert].join(" ") : [stl.input, stl.light, stl.textArea, stl.alert].join(" ");
        }
        setTimeout(() => {
            feedBackRef.current.className = theme === darkTheme ? stl.input : [stl.input, stl.light].join(" ");
            messageRef.current.className = theme === darkTheme ? [stl.input, stl.textArea].join(" ") : [stl.input, stl.light, stl.textArea].join(" ");
        }, 3000)
    }

    const openCloseFetchResultMessageArea = () => {
        resultMessageRef.current.setAttribute("style", "bottom: 0")
        const timeout = setTimeout(() => {
            resultMessageRef.current.setAttribute("style", "bottom: -80px")
        }, 5000)
    }

    useEffect(() => {
        if (isFetching) {
            submitButtonRef.current.innerHTML = '';
            submitButtonRef.current.className = stl.loader;
        } else {
            submitButtonRef.current.innerHTML = submitButtonText;
            submitButtonRef.current.className = theme === darkTheme ? stl.submitButton : [stl.submitButton, stl.light].join(" ");
        }
    }, [isFetching])

    return (<div className={theme === darkTheme ? stl.main : [stl.main, stl.light].join(" ")} ref={contactsRef}>
        <div className={stl.screenPartContainer}>
            <div className={stl.contactsContainer}>
                <span className={stl.title}>{contactsTitle}</span>
                <div className={theme === darkTheme ? stl.contacts : [stl.contacts, stl.light].join(" ")}>
                    <div className={stl.inputContainer}>
                        <input
                            className={theme === darkTheme ? stl.input : [stl.input, stl.light].join(" ")}
                            placeholder={yourName}
                            ref={nameRef}
                        />
                    </div>
                    <div className={stl.inputContainer}>
                        <input
                            className={theme === darkTheme ? stl.input : [stl.input, stl.light].join(" ")}
                            placeholder={feedback}
                            ref={feedBackRef}
                        />
                    </div>
                    <div className={stl.textAreaContainer}>
                            <textarea
                                className={theme === darkTheme ? [stl.input, stl.textArea].join(" ") : [stl.input, stl.light, stl.textArea].join(" ")}
                                placeholder={message}
                                ref={messageRef}
                            />
                    </div>
                    <div className={stl.submitButtonContainer}>
                        <div
                            className={theme === darkTheme ? stl.submitButton : [stl.submitButton, stl.light].join(" ")}
                            onClick={submit}
                            ref={submitButtonRef}>
                            {submitButtonText}
                        </div>
                    </div>
                </div>
                <div className={stl.photographersArea}>
                    <div className={stl.photographersTitle} translate={"no"}>{photoBy}</div>
                    {
                        photographers.map(photographer =>
                            <div key={photographer.name} className={stl.photographerLinkContainer}>
                                <a href={photographer.link} translate={"no"} className={stl.photographerLink}>{photographer.name}</a>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
        <div className={theme === darkTheme ? stl.fetchResult : [stl.fetchResult, stl.light].join(" ")}
             ref={resultMessageRef}>{fetchResultMessage}</div>
    </div>);
};

export default Contacts;