import React, {useContext, useEffect, useRef, useState} from 'react';
import stl from "./Resume.module.css"
import {AppContext} from "../../context/context";
import {darkTheme, desktop, mobile} from "../../utils/serviceVariables";
import img from "../../source/images/resume-img.jpg"
import {
    imgAlt,
    miacLink,
    resumeText1,
    resumeText12, resumeText10,
    resumeText11,
    resumeText7,
    resumeText8,
    resumeText9,
    resumeTitle
} from "../../source/content";
import Experience from "./experience/Experience";

const Resume = ({resumeRef, setImgIsLoaded}) => {
    const leftSideContainer = useRef(null);
    const rightSideContainer = useRef(null);
    const photoRef = useRef(null);

    const {theme, deviceMode} = useContext(AppContext);
    const [isVisible, setIsVisible] = useState(false);
    setImgIsLoaded(true)
    // Listens scroll and causes 'setIsResumeContentVisible'.
    useEffect(() => {
        rightSideContainer.current.style.height = leftSideContainer.current.clientHeight + "px";

        window.addEventListener('scroll', setIsResumeContentVisible)
        return () => window.removeEventListener('scroll', setIsResumeContentVisible)
    }, [])

    // Makes 'isVisible' true when resumeRef appears on the screen first time.
    const setIsResumeContentVisible = () => {
        if (resumeRef.current.getBoundingClientRect().top * 2 < document.documentElement.clientHeight) {
            setIsVisible(true);
        }
        if (deviceMode === desktop) {
            const coordinates = resumeRef.current.getBoundingClientRect()
            photoRef.current.style.left = (rightSideContainer.current.offsetWidth / 2 - 175) + "px";
            if (coordinates.top < window.innerHeight / 2 && coordinates.bottom > window.innerHeight / 2) {
                const newTop = getPhotoTop(coordinates);
                if (newTop >= 50 && newTop <= resumeRef.current.clientHeight - 385) {
                    photoRef.current.style.top = `${getPhotoTop(coordinates)}px`;
                }
            }
        }
    }

    const getPhotoTop = (coordinates) => {
        if (coordinates.top > 50 && coordinates.top + resumeRef.current.clientHeight > window.innerHeight) {
            return (window.innerHeight - coordinates.top) / 2 - 175;
        } else if (coordinates.top < 50 && coordinates.top + resumeRef.current.clientHeight > window.innerHeight) {
            return window.innerHeight / 2 - 200 + Math.abs(50 - coordinates.top);
        } else if (coordinates.top < 50 && coordinates.top + resumeRef.current.clientHeight < window.innerHeight) {
            return (resumeRef.current.clientHeight - coordinates.top + 50) / 2 - 175;
        }
    }

    return (
        <div className={theme === darkTheme ? stl.main : [stl.main, stl.light].join(" ")} ref={resumeRef}>
            <div
                className={isVisible ? [stl.startAnimation, stl.active].join(" ") : [stl.startAnimation, stl.notActive].join(" ")}>

                <div className={stl.leftSide} ref={leftSideContainer}>
                    <div className={stl.leftTextContainer}>
                    <span
                        className={deviceMode === desktop ? stl.notVisible : stl.mobileTitle}>{resumeTitle}</span>
                        <div className={stl.paragraph}>
                            <div><span
                                className={theme === darkTheme ? stl.text : [stl.text, stl.light].join(" ")}>{resumeText1}</span>
                            </div>
                        </div>
                        <div className={stl.paragraph}>
                            <div><span
                                className={theme === darkTheme ? stl.text : [stl.text, stl.light].join(" ")}>{resumeText7}</span>
                            </div>
                        </div>
                        <div className={stl.paragraph}>
                            <div><span
                                className={theme === darkTheme ? stl.text : [stl.text, stl.light].join(" ")}>{resumeText8}</span>
                            </div>
                        </div>
                    </div>
                    <div className={stl.leftExperienceContainer}>
                        <Experience/>
                    </div>
                </div>
                <div className={stl.rightSide} ref={rightSideContainer}>
                    {
                        deviceMode === desktop &&
                        <div className={stl.imageContainer} ref={photoRef}>
                            <img
                                onLoad={() => setImgIsLoaded(true)}
                                src={img}
                                className={stl.image}
                                alt={imgAlt}
                            />
                            <span className={stl.title}>{resumeTitle}</span>
                            <div className={stl.leftTopCorner}/>
                            <div className={stl.rightBottomCorner}/>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default Resume;